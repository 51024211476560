import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import 'boxicons/css/boxicons.min.css';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { track } from '@vercel/analytics';


const UNSPLASH_ACCESS_KEY = 'kqZTs-uCVhCF7ma9oyWpmxUajQpD44WcNRFVyzhoorA';

const TextPreview = () => {
	  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [previewData, setPreviewData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#0000FF'); // Default blue
  const [selectedColor, setSelectedColor] = useState('#0000FF'); // State for selected color
  const [loading, setLoading] = useState(false); // State for loading
  const [backgroundImage, setBackgroundImage] = useState(null); // State for background image
  const [unsplashImages, setUnsplashImages] = useState([]); // State for Unsplash images
  const [searchQuery, setSearchQuery] = useState(''); // State for Unsplash search query
  const previewRef = useRef(null);
  const [activeTab, setActiveTab] = useState('Tab1');
  
 
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setImageLoaded(false);
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`https://www.pixelliongroup.com/link_preview_api.php?url=${encodeURIComponent()}`);
      setPreviewData(response.data);
    } catch (error) {
      console.error('Error fetching the URL preview:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

 const handleDownload = async () => {
 if (!previewRef.current) return;
track('Download');
 setTimeout(async () => {
   const element = previewRef.current;
   element.style.opacity = '1'; // Ensure visibility during capture
   element.style.top = '0'; // Ensure element is within the visible area

   const canvas = await html2canvas(element, {
     useCORS: true,
     allowTaint: true,
     backgroundColor: 'transparent', // Ensure transparency for background image
     width: 1080,
     height: 1920,
     scale: 2 // Increase the scale to ensure high resolution
   });

   element.style.opacity = '0'; // Hide again after capture
   element.style.top = '-9999px'; // Move it far off-screen again

   canvas.toBlob((blob) => {
     const link = document.createElement('a');
     link.href = URL.createObjectURL(blob);
     link.download = 'url_preview.png';
     link.click();
     toast.success('Download complete!', {
       position: "top-center",
       autoClose: 5000,
       hideProgressBar: true,
       closeOnClick: true,
       closeButton: false,
       pauseOnHover: true,
       draggable: false,
       progress: undefined,
       className: "!bg-zinc-900 !text-green-600 border border-1 border-zinc-800 !text-center !w-fit text-md uppercase !rounded-full !px-10 !pt-0 !pb-0 !h-fit",
     });
   }, 'image/png');
 }, 300); // Increase the timeout to ensure rendering

  };



  const handleColorButtonClick = (color) => {
    setBackgroundColor(color);
    setSelectedColor(color);
    setBackgroundImage(null); // Clear background image when a color is selected
     };

  const handleBackgroundImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
        setSelectedColor(null); // Clear selected color when an image is chosen
      };
      reader.readAsDataURL(file);
    }
  };
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleBackgroundImageChange(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  
  const searchUnsplash = useCallback(async (query) => {
  setUnsplashLoading(true); // Start loading
  try {
    const response = await axios.get(`https://api.unsplash.com/search/photos`, {
      params: { query: query, orientation: 'portrait', per_page: 16 },
      headers: {
        Authorization: `Client-ID ${UNSPLASH_ACCESS_KEY}`
      }
    });

    setUnsplashImages(response.data.results);
  } catch (error) {
    console.error('Error fetching Unsplash images:', error);
  } finally {
    setUnsplashLoading(false); // Stop loading
  }
}, []);

      
      const [unsplashLoading, setUnsplashLoading] = useState(false);

  const handleUnsplashSearchSubmit = (e) => {
  e.preventDefault();
  searchUnsplash(searchQuery);
};

  const handleUnsplashImageClick = (url) => {
    setBackgroundImage(url);
    setSelectedColor(null); // Clear selected color when an image is chosen
  };



useEffect(() => {
  searchUnsplash('random');
}, [searchUnsplash]);





  return (
	  
    <div className=" w-full h-full lg:h-screen flex items-center justify-center bg-zinc-950">
      <div className="w-full h-full  max-w-[1400px] p-5 -mt-12 md:p-12 lg:p-48 ">
      <div className="flex justify-center items-center text-bold my-12 text-zinc-300 lg:text-7xl text-3xl">
      <img src="urlstory.png" className="lg:w-20 w-10 mr-2 lg:mr-5 "  alt="urlstory logo"/> ur/story
      </div>
      
     
           
        <form onSubmit={handleSubmit} className="mb-4">
        <div className="ring ring-1 ring-zinc-800 bg-zinc-900 rounded-lg p-5">
          <div className="grid grid-cols-8 gap-5">
            <input
              type="text"
              id="title"
              className="focus:outline-none rounded-lg w-full bg-zinc-800 border border-1 border-zinc-700 text-white px-5 py-3 col-span-8 lg:col-span-8"
              value={title}
              placeholder="Enter title"
              onChange={(e) => setTitle(e.target.value.slice(0, 50))}
              required
              maxLength={50}
            />
            <textarea
              id="description"
              className="focus:outline-none bg-zinc-800 border border-1 border-zinc-700 rounded-lg h-fit w-full text-white px-5 py-3 col-span-8 lg:col-span-8"
              value={description}
              placeholder="Enter description"
              onChange={(e) => setDescription(e.target.value.slice(0, 250))}
              required
              maxLength={250}
            />
            <div className="col-span-8 text-left text-zinc-500 text-sm">
        {description.length}/250
      </div>
            <button onClick={() => { track('Text Generate');}} type="submit" className="w-full mt-2 md:mt-0 lg:mt-0 hover:bg-blue-700 px-5 py-3 bg-blue-800 text-white uppercase rounded-lg col-span-8">
              Generate <i className='bx bxs-magic-wand bx-fw bx-tada'></i>
            </button>
          </div>
        </div>
      </form>

        {loading ? (
        <div className="flex justify-center items-center text-zinc-600 mt-10">
            <i class='bx bx-loader bx-spin bx-md bx-rotate-180' ></i>
          </div>
        ) : (
          <div className="grid grid-cols-8 lg:gap-10 gap-2 mt-10">
            {previewData && (
              <>
                 <div className="col-span-8 lg:col-span-5  ring ring-0 lg:ring-1 ring-zinc-800 bg-zinc-950 lg:bg-zinc-900 min-h-[650px] rounded-lg lg:p-8 p-0">
                  <label className="block text-sm text-zinc-500 uppercase"><i class='bx bxs-carousel bx-fw' ></i>Background Setting</label>
                  
                  
                  <div className="tabs mb-4 space-y-2 space-x-1 lg:space-x-5">
        <button
          className={`mb-2 mt-10 text-zinc-500 uppercase text-xs px-5 py-3 rounded-lg ${
            activeTab === 'Tab1' ? 'bg-zinc-800 ring ring-1 ring-zinc-700 text-zinc-200' : 'bg-zinc-900 hover:bg-zinc-800 hover:ring hover:ring-1 hover:ring-zinc-700 text-white'
          }`}
          onClick={() => handleTabClick('Tab1')}
        >
         <i className='bx bxs-square bx-fw'></i> Colors
        </button>
        <button
          className={`mb-2 mt-10 text-zinc-500 uppercase text-xs px-5 py-3 rounded-lg ${
            activeTab === 'Tab2' ? 'bg-zinc-800 ring ring-1 ring-zinc-700 text-zinc-200' : 'bg-zinc-900 hover:bg-zinc-800 hover:ring hover:ring-1 hover:ring-zinc-700 text-white'
          }`}
          onClick={() => handleTabClick('Tab2')}
        >
         <i className='bx bxs-image-alt bx-fw'></i> Image
        </button>
        <button
          className={`mb-2 mt-10 text-zinc-500 uppercase text-xs px-5 py-3 rounded-lg ${
            activeTab === 'Tab3' ? 'bg-zinc-800 ring ring-1 ring-zinc-700 text-zinc-200' : 'bg-zinc-900 hover:bg-zinc-800 hover:ring hover:ring-1 hover:ring-zinc-700 text-white'
          }`}
          onClick={() => handleTabClick('Tab3')}
        >
          <i className='bx bxl-unsplash bx-fw'></i> Unsplash
        </button>
       
      </div>
      <div className="tab-content bg-zinc-800 p-5 rounded-lg ring ring-1 ring-zinc-700">
        {activeTab === 'Tab1' && <div><h3 className="mb-2 text-zinc-500 uppercase text-xs">Solid Colors</h3>
                    <div className="w-full grid grid-cols-7 lg:grid-cols-12 gap-2 mb-4 w-full">
						 <button
						  type="button"
						  className={`bg-gray-100 w-8 h-8 rounded-lg ${selectedColor === '#f5f6f8' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-gray-100'}`}
						  onClick={() => handleColorButtonClick('#f5f6f8')}
						/>
						
						<button
						  type="button"
						  className={`bg-yellow-200 w-8 h-8 rounded-lg ${selectedColor === '#fcfea4' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-yellow-200'}`}
						  onClick={() => handleColorButtonClick('#fcfea4')}
						/>
						
						<button
						  type="button"
						  className={`bg-green-100 w-8 h-8 rounded-lg ${selectedColor === '#c0feb9' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-green-100'}`}
						  onClick={() => handleColorButtonClick('#c0feb9')}/>
						
						<button
						  type="button"
						  className={`bg-blue-200 w-8 h-8 rounded-lg ${selectedColor === '#9ed9eb' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-blue-200'}`}
						  onClick={() => handleColorButtonClick('#9ed9eb')}/>
						
						<button
						  type="button"
						  className={`bg-indigo-200 w-8 h-8 rounded-lg ${selectedColor === '#ada0fe' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-indigo-200'}`}
						  onClick={() => handleColorButtonClick('#ada0fe')}/>
						
						<button
						  type="button"
						  className={`bg-pink-300 w-8 h-8 rounded-lg ${selectedColor === '#f9b7f3' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-pink-300'}`}
						  onClick={() => handleColorButtonClick('#f9b7f3')}/>
						
						<button
						  type="button"
						  className={`bg-gray-200 w-8 h-8 rounded-lg ${selectedColor === '#d6dadf' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-gray-200'}`}
						  onClick={() => handleColorButtonClick('#d6dadf')}/>
						
						<button
						  type="button"
						  className={`bg-orange-300 w-8 h-8 rounded-lg ${selectedColor === '#fabf95' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-orange-300'}`}
						  onClick={() => handleColorButtonClick('#fabf95')}/>
						
						<button
						  type="button"
						  className={`bg-yellow-400 w-8 h-8 rounded-lg ${selectedColor === '#babf18' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-yellow-400'}`}
						  onClick={() => handleColorButtonClick('#babf18')}/>
						
						<button
						  type="button"
						  className={`bg-yellow-500 w-8 h-8 rounded-lg ${selectedColor === '#fbbe22' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-yellow-500'}`}
						  onClick={() => handleColorButtonClick('#fbbe22')}/>
						
						<button
						  type="button"
						  className={`bg-green-500 w-8 h-8 rounded-lg ${selectedColor === '#44bf46' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-green-500'}`}
						  onClick={() => handleColorButtonClick('#44bf46')}/>
						
						<button
						  type="button"
						  className={`bg-teal-500 w-8 h-8 rounded-lg ${selectedColor === '#34828b' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-teal-500'}`}
						  onClick={() => handleColorButtonClick('#34828b')}/>
						
						<button
						  type="button"
						  className={`bg-blue-500 w-8 h-8 rounded-lg ${selectedColor === '#0000FF' ?  'ring ring-4 ring-zinc-700 border border-2 border-zinc-900'  : 'bg-blue-500'}`}
						  onClick={() => handleColorButtonClick('#0000FF')}/>
						  
						<button
						  type="button"
						  className={`bg-blue-600 w-8 h-8 rounded-lg ${selectedColor === '#1f70b9' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-blue-600'}`}
						  onClick={() => handleColorButtonClick('#1f70b9')}/>
						
						<button
						  type="button"
						  className={`bg-orange-500 w-8 h-8 rounded-lg ${selectedColor === '#f77c3a' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-orange-500'}`}
						  onClick={() => handleColorButtonClick('#f77c3a')}/>
						
						<button
						  type="button"
						  className={`bg-red-500 w-8 h-8 rounded-lg ${selectedColor === '#f53f4c' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-red-500'}`}
						  onClick={() => handleColorButtonClick('#f53f4c')}/>
						
						<button
						  type="button"
						  className={`bg-pink-600 w-8 h-8 rounded-lg ${selectedColor === '#de3274' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-pink-600'}`}
						  onClick={() => handleColorButtonClick('#de3274')}/>
						
						<button
						  type="button"
						  className={`bg-lime-500 w-8 h-8 rounded-lg ${selectedColor === '#7ebf09' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-lime-500'}`}
						  onClick={() => handleColorButtonClick('#7ebf09')}/>
						
						<button
						  type="button"
						  className={`bg-purple-700 w-8 h-8 rounded-lg ${selectedColor === '#563f7f' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-purple-700'}`}
						  onClick={() => handleColorButtonClick('#563f7f')}/>
						
						<button
						  type="button"
						  className={`bg-indigo-700 w-8 h-8 rounded-lg ${selectedColor === '#3c4e97' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-indigo-700'}`}
						  onClick={() => handleColorButtonClick('#3c4e97')}/>
						
						<button
						  type="button"
						  className={`bg-gray-400 w-8 h-8 rounded-lg ${selectedColor === '#a0a8af' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-gray-400'}`}
						  onClick={() => handleColorButtonClick('#a0a8af')}/>
						
						<button
						  type="button"
						  className={`bg-gray-500 w-8 h-8 rounded-lg ${selectedColor === '#858386' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-gray-500'}`}
						  onClick={() => handleColorButtonClick('#858386')}/>
						
						<button
						  type="button"
						  className={`bg-gray-800 w-8 h-8 rounded-lg ${selectedColor === '#3a3f44' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-gray-800'}`}
						  onClick={() => handleColorButtonClick('#3a3f44')}/>
						
						<button
						  type="button"
						  className={`bg-yellow-800 w-8 h-8 rounded-lg ${selectedColor === '#7b5e3d' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : 'bg-yellow-800'}`}
						  onClick={() => handleColorButtonClick('#7b5e3d')}/>

                      
                      
                    </div>
                                      
                    <h3 className="mb-2 mt-10 text-zinc-500 uppercase text-xs">Gradients</h3>
                    <div className="w-full grid grid-cols-7 lg:grid-cols-12 gap-2 mb-4 w-full">
                      <button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-yellow-200 via-yellow-300 to-yellow-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #FFFFE0, #FFFACD, #FFD700)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #FFFFE0, #FFFACD, #FFD700)')}
					/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-green-200 via-green-300 to-green-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #98FB98, #90EE90, #32CD32)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #98FB98, #90EE90, #32CD32)')}
					/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-blue-200 via-blue-300 to-blue-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #ADD8E6, #87CEEB, #4682B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #ADD8E6, #87CEEB, #4682B4)')}
					/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-purple-200 via-purple-300 to-purple-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #E6E6FA, #D8BFD8, #9370DB)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #E6E6FA, #D8BFD8, #9370DB)')}
					/>
					
					
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-yellow-200 via-yellow-400 to-yellow-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FFFFE0, #FFD700, #FFA500)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FFFFE0, #FFD700, #FFA500)')}
					/>
					
					
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-blue-200 via-blue-400 to-blue-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #ADD8E6, #00BFFF, #4682B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #ADD8E6, #00BFFF, #4682B4)')}
					/>

					
					
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-red-200 via-red-300 to-red-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #FFC0C0, #FF7070, #FF0000)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #FFC0C0, #FF7070, #FF0000)')}/>
					
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-red-200 via-red-400 to-red-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FFC0C0, #FF4500, #FF0000)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FFC0C0, #FF4500, #FF0000)')}/>
					
				
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-yellow-300 via-red-300 to-red-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #FFFFE0, #FFB6C1, #FF0000)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #FFFFE0, #FFB6C1, #FF0000)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-green-300 via-teal-300 to-blue-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #98FB98, #00CED1, #0000FF)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #98FB98, #00CED1, #0000FF)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-blue-300 via-purple-300 to-pink-500 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #87CEEB, #D8BFD8, #FF69B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #87CEEB, #D8BFD8, #FF69B4)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-yellow-300 via-green-400 to-blue-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FFFFE0, #32CD32, #0000FF)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FFFFE0, #32CD32, #0000FF)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-green-300 via-blue-400 to-purple-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #98FB98, #4682B4, #6A5ACD)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #98FB98, #4682B4, #6A5ACD)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-blue-300 via-purple-400 to-pink-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #87CEEB, #9370DB, #C71585)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #87CEEB, #9370DB, #C71585)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-red-400 via-orange-400 to-yellow-600 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #FF6347, #FFA500, #FFD700)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #FF6347, #FFA500, #FFD700)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-green-400 via-teal-400 to-blue-600 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #32CD32, #00CED1, #4682B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #32CD32, #00CED1, #4682B4)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-blue-400 via-purple-400 to-pink-600 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #1E90FF, #9370DB, #FF69B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #1E90FF, #9370DB, #FF69B4)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-red-400 via-purple-400 to-purple-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FF6347, #9370DB, #6A5ACD)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FF6347, #9370DB, #6A5ACD)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-green-400 via-teal-400 to-blue-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #32CD32, #00CED1, #4682B4)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #32CD32, #00CED1, #4682B4)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-yellow-400 via-orange-400 to-red-600 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FFD700, #FFA500, #FF4500)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FFD700, #FFA500, #FF4500)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-yellow-500 via-green-500 to-blue-700 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #FFD700, #00FF7F, #00008B)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #FFD700, #00FF7F, #00008B)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-bl from-purple-500 via-pink-500 to-red-700 rounded-md ${selectedColor === 'linear-gradient(to bottom left, #9370DB, #FF69B4, #8B0000)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom left, #9370DB, #FF69B4, #8B0000)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-teal-500 via-blue-500 to-indigo-700 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #20B2AA, #0000FF, #4B0082)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #20B2AA, #0000FF, #4B0082)')}/>
					
					<button
					  type="button"
					  className={`w-8 h-8 bg-gradient-to-br from-pink-500 via-red-500 to-purple-700 rounded-md ${selectedColor === 'linear-gradient(to bottom right, #FF69B4, #FF0000, #800080)' ? 'ring ring-4 ring-zinc-700 border border-2 border-zinc-900' : ''}`}
					  onClick={() => handleColorButtonClick('linear-gradient(to bottom right, #FF69B4, #FF0000, #800080)')}/>

                    </div></div>}
        {activeTab === 'Tab2' && <div>
                   
                      
                       <div
                        {...getRootProps()}
                        className="bg-zinc-800 text-zinc-500 hover:text-zinc-400 w-full h-32 flex items-center justify-center rounded-lg cursor-pointer"
                        onClick={() => document.getElementById('backgroundImageInput').click()}
                      > <i className='bx bxs-image-alt bx-md bx-fw'></i> Choose image or drop here
                      <input
                      {...getInputProps()}
                        type="file"
                        id="backgroundImageInput"
                        accept="image/*"
                        onChange={handleBackgroundImageChange}
                        className="hidden"
                      /></div>
                      
                                            
                      </div>}
        {activeTab === 'Tab3' && <div>
	        
	        <form onSubmit={handleUnsplashSearchSubmit}>
                      <label className="block text-zinc-500 text-xs uppercase mb-2">Search Unsplash</label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          className="focus:outline-none rounded-lg w-full bg-zinc-900 border border-1 border-zinc-700 text-sm text-white px-5 py-3"
                          value={searchQuery}
                          placeholder="Search"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                                              <button type="submit" className="ml-2 hover:bg-blue-700 hover:border-blue-600 p-3 bg-blue-900 border border-1 border-blue-800 text-sm text-blue-200 uppercase rounded-lg">
                         <i className='bx bxs-search bx-xs bx-fw'></i>
                        </button>
                        
                      </div>


                    </form>

                    {unsplashLoading ? (
          <div className="flex justify-center items-center text-zinc-600 mt-10">
            <i class='bx bx-loader bx-spin bx-md bx-rotate-180' ></i>
          </div>
        ) : (
          <div className="mt-4 grid grid-cols-4 lg:grid-cols-8 gap-4">
            {unsplashImages.map((image) => (
              <img
                key={image.id}
                src={image.urls.thumb}
                alt={image.alt_description}
                className="cursor-pointer border border-1 border-zinc-700 rounded-md h-20 lg:h-16 w-16"
                onClick={() => handleUnsplashImageClick(image.urls.regular)}
              />
            ))}  </div>
)}
	        
	        </div>}
      </div>
                  
                  <div>
                    
                    
                    
                    {(!previewData.image || imageLoaded) && (
                      <button onClick={handleDownload} className="lg:w-fit w-full border border-1 border-zinc-100 mt-12 px-7 py-3 bg-zinc-300 text-zinc-700 rounded-lg uppercase">
                      <div className="grid grid-cols-3">
                      <div className="col-span-1 flex justify-center  items-center">
                      <i className='bx bxs-download bx-sm mr-5 mt-1 text-zinc-500'></i>
                      </div>
                      <div className="col-span-2  text-left">
                      <p className="font-bold">Download</p><p className="text-xs -mt-1">as png</p>
                      </div>
                      </div>
                      </button>
                      
                     
                   
                    )}
                  </div>  
                </div>
                <div
                  id="preview-content"
                  className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                  ref={previewRef}
                  style={{
                    //opacity: 0, // Use opacity to hide it while keeping it renderable
                    backgroundColor: backgroundImage ? 'transparent' : (backgroundColor.startsWith('linear-gradient') ? '#FFFFFF' : backgroundColor),
                    background: backgroundImage ? `url(${backgroundImage}) no-repeat center/cover` : (backgroundColor.startsWith('linear-gradient') ? backgroundColor : undefined),
                    position: 'absolute', // Ensure it doesn't affect the layout
                    top: '-9999px', // Move it far off-screen
                    width: '1080px',
                    height: '1920px',
                    zIndex: -100 // Ensure it doesn't interfere with user interactions
                  }}
                >
                  <div
                    className="p-12 border rounded-2xl -mt-36 shadow-lg bg-white"
                    style={{
                      width: '80%',
                      height: 'auto',
                      maxWidth: '864px'
                    }}
                  >
                    <h2 className="text-7xl font-bold">{title}</h2>
                <p className="text-gray-700 text-5xl leading-normal mt-8 mb-5">{description}</p>                    
                     </div>
                  
                 
                  
                </div>
                <div className="col-span-8 lg:col-span-3 rounded-lg ring ring-5 ring-zinc-800">
                  
                  <div
                    id="small-preview-content"
                    className="flex items-center justify-center rounded-lg ring ring-5 ring-zinc-800"
                    style={{
                      backgroundColor: backgroundImage ? 'transparent' : (backgroundColor.startsWith('linear-gradient') ? '#FFFFFF' : backgroundColor),
                      background: backgroundImage ? `url(${backgroundImage}) no-repeat center/cover` : (backgroundColor.startsWith('linear-gradient') ? backgroundColor : undefined),
                      width: '100%',
                      height: '650px',
                      margin: '0 auto'
                    }}
                  >
                    <div
                      className="p-4 border rounded-2xl shadow-lg bg-white"
                      style={{
                        width: '80%',
                        height: 'auto',
                      }}
                    >
                      <h2 className="text-xl font-bold">{title}</h2>
                <p className="text-gray-700 text-sm mt-2">{description}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default TextPreview;
