import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import LinkPreview from './LinkPreview';
import TextPreview from './TextPreview';
import ChangeLog from './ChangeLog';
import Pricing from './Pricing';
import Login from './Login';
import PrivacyPolicy from './PrivacyPolicy';
import Report from './Report';
import Help from './Help';
import Contact from './Contact';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = (e) => {
    if (e.target.id === 'menu-overlay') {
      setIsMenuOpen(false);
    }
  };

  return (
          <Router>
     
          
        <div className="bg-zinc-950">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="absolute left-2 top-2 text-zinc-500 text-3xl p-4"
          >
            ☰
          </button>
          
                
          <Link className="absolute left-14 top-8 text-zinc-500 text-lg" to="/changelog" onClick={() => setIsMenuOpen(false)}><i class='bx bxs-news bx-fw'></i></Link> 

          <nav className="relative">
            {isMenuOpen && (
              <div
                id="menu-overlay"
                className="fixed inset-0 bg-zinc-950 bg-opacity-100 z-50 flex justify-center items-center"
                onClick={handleCloseMenu}
              >
                <div className="bg-zinc-950 lg:w-2/5 w-5/5 p-6 text-center text-white">
                  <div className="mb-10">
                    <div className="flex justify-center items-center text-bold my-12 text-zinc-300 lg:text-7xl text-3xl">
      <img src="urlstory.png" className="lg:w-20 w-10 mr-2 lg:mr-5 "  alt="urlstory logo"/> ur/story       </div>
                    <h1 className="text-center text-3xl  py-5 text-zinc-300">Transforming Links into Visual Stories</h1>
                    <p className="text-center mb-12 text-zinc-500">Create stunning URL previews for social media with URL Story</p>
                    
                  </div>
                  <ul className="grid grid-cols-2 gap-5">
                    <li className="bg-zinc-900 border border-1 border-zinc-800 rounded-lg p-3 lg:p-5">
                      <Link to="/" onClick={() => setIsMenuOpen(false)}><i class='bx bx-link bx-fw' ></i> From Url</Link>
                    </li>
                    <li className="bg-zinc-900 border border-1 border-zinc-800 rounded-lg p-3 lg:p-5">
                      <Link to="/textpreview" onClick={() => setIsMenuOpen(false)}><i class='bx bx-text bx-fw' ></i> Custom Text</Link>
                    </li>
                    
                  </ul>
                  
                                   <p className="text-zinc-400 uppercase text-sm my-12 lg:my-24">create with<i class='bx bxs-heart bx-fw bx-burst text-red-500' ></i>love by <a className="bg-zinc-900 border border-1 border-zinc-800 rounded-lg px-2 py-1 hover:bg-zinc-800" href="https://www.twitter.com/pixellionmm" rel="noreferrer" title="Pixellion" target="_blank"><i class='bx bxl-twitter bx-fw hover:text-blue-500' ></i>pixellionmm</a></p>
                </div>
               
              </div>
            )}
          </nav>
          
          <Routes>
            <Route path="/" element={<LinkPreview />} />
            <Route path="/textpreview" element={<TextPreview />} />
            <Route path="/changelog" element={<ChangeLog />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/q/:url" component={LinkPreview} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/report" element={<Report />} />
            <Route path="/help" element={<Help />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
 
  );
};

export default App;
