import React from 'react';

const Report = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold">Report</h1>
      <p className="mt-4">Your privacy policy content goes here.</p>
    </div>
  );
};

export default Report;
