import React from 'react';

const ChangeLog = () => {
  return (
   <div className=" w-full h-full lg:h-screen flex items-center justify-center bg-zinc-950 mt-32">
      <div className="w-full h-full  max-w-[1400px] p-5 -mt-12 md:p-12 lg:p-48">
      <h1 className="text-2xl lg:text-5xl text-zinc-300 uppercase mb-5">What's new</h1>
      <div className="border-l-2 border-zinc-300 pl-5">
      
       <label className="text-zinc-300 rounded-full text-sm px-2 py-1 bg-zinc-800 ring ring-[10px] ring-zinc-950 border border-zinc-700 -ml-8"><i class="bx bxs-extension bx-fw text-green-500"></i>28 MAY 24 </label>
      <div className="bg-zinc-900 border border-1 border-zinc-800   rounded-lg w-full px-10 py-7 text-zinc-500 my-5">
      <h1 className="text-lg uppercase text-zinc-400 font-bold">New Layouts</h1>
      <p className="my-2">Added layout chooser with multiple options.</p>
      </div>

      
      <label className="text-zinc-300 rounded-full text-sm px-2 py-1 bg-zinc-800 ring ring-[10px] ring-zinc-950 border border-zinc-700 -ml-8"><i class="bx bxs-circle bx-fw text-green-500"></i>27 MAY 24 </label>
      <div className="bg-zinc-900 border border-1 border-zinc-800 rounded-lg w-full px-10 py-7 text-zinc-500 my-5">
      <h1 className="text-lg uppercase text-zinc-400 font-bold">API Update</h1>
       <p className="my-2">API updated logic to support CROS.</p>
      </div>
      
      <label className="text-zinc-300 rounded-full text-sm px-2 py-1 bg-zinc-800 ring ring-[10px] ring-zinc-950 border border-zinc-700 -ml-8"><i class="bx bxs-circle bx-fw text-green-500"></i>26 MAY 24 </label>
      <div className="bg-zinc-900 border border-1 border-zinc-800 rounded-lg w-full px-10 py-7 text-zinc-500 my-5">
      <h1 className="text-lg uppercase text-zinc-400 font-bold">Update</h1>
       <p className="my-2">Bug fixes and improvements.</p>
      </div>

      
      </div>
      
    </div>
    </div>
  );
};

export default ChangeLog;
